import React from 'react';

export enum ROUTES {
  Home = '/',
  Login = '/login',
  Register = '/register',
  ResetPassword = '/reset-password',
  ResetCheckInBox = '/reset-check-in-box',
  SetNewPassword = '/set-password',
  AuthCreateOrganization = '/create-organization',
}

export const COMPONENTS = {
  Login: React.lazy(() => import('./pages/authentication/Login')),
  Register: React.lazy(() => import('./pages/authentication/Register')),
  ResetPassword: React.lazy(
    () => import('./pages/authentication/ResetPasswordFlow/ResetPassword')
  ),
  ResetCheckInBox: React.lazy(
    () => import('./pages/authentication/ResetPasswordFlow/ResetCheckInBox')
  ),
  SetNewPassword: React.lazy(
    () => import('./pages/authentication/ResetPasswordFlow/SetNewPassword')
  ),
  AuthCreateOrganization: React.lazy(
    () => import('./pages/authentication/AuthCreateOrganization')
  ),
  Dashboard: React.lazy(() => import('./pages/Dashboard')),
  ListProducts: React.lazy(
    () => import('./components/resources/listComponents/products/ListProducts')
  ),
  ListOrders: React.lazy(
    () => import('./components/resources/listComponents/orders/ListOrders')
  ),
  EditProduct: React.lazy(
    () => import('./components/resources/editComponents/products/EditProduct')
  ),
  EditOrganization: React.lazy(
    () =>
      import(
        './components/resources/editComponents/organizations/EditOrganization'
      )
  ),
  CreateProduct: React.lazy(
    () =>
      import('./components/resources/createComponents/products/CreateProduct')
  ),
  ShowOrder: React.lazy(
    () => import('./components/resources/showComponents/orders/ShowOrder')
  ),
};
