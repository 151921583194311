import { InitOptions } from 'i18next';
import { getLanguageCodeFromBrowser } from '@infra/helpers';

export const i18nextConfig: InitOptions = {
  debug: import.meta.env.VITE_APP_ENV === 'development',
  load: 'languageOnly',
  fallbackLng: 'en_GB',
  lng: getLanguageCodeFromBrowser(),
  ns: ['console', 'common', 'diagnostic-request-parameters'],
  defaultNS: 'console',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    useSuspense: false,
  },
  initImmediate: false,
  parseMissingKeyHandler: () => '',
};
