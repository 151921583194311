import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import HyperDX from '@hyperdx/browser';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: import.meta.env.VITE_APP_ENV,
  tracesSampleRate: 0,
});

HyperDX.init({
  url: import.meta.env.VITE_HYPERDX_URL,
  apiKey: import.meta.env.VITE_HYPERDX_API_KEY,
  service: `console2-ui-${import.meta.env.VITE_APP_ENV}`,
  tracePropagationTargets: [/`*.daslab.health`/i], // Set to link traces from frontend to backend requests
  consoleCapture: false, // Capture console logs (default false)
  advancedNetworkCapture: false, // Capture full HTTP request/response headers and bodies (default false)
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
