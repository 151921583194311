import i18n from 'i18next';
import { initializeI18n } from '@infra/application-shell';
import { i18nextConfig } from './i18next';
import { convertRaTranslationsToI18next } from 'ra-i18n-i18next';
import enRaOverrides from '@/assets/ra/en_GB.json';
import deRaOverrides from '@/assets/ra/de_DE.json';
import esRaOverrides from '@/assets/ra/es_ES.json';
import enRaMessages from 'ra-language-english';
import deRaMessages from 'ra-language-german';
import esRaMessages from 'ra-language-spanish';
import { deepmerge } from '@mui/utils';

const version = 'latest';
initializeI18n(
  i18nextConfig,
  { en: version, es: version, de: version },
  version
);
i18n.addResourceBundle(
  'en_GB',
  'console',
  deepmerge(convertRaTranslationsToI18next(enRaMessages), enRaOverrides),
  true,
  false
);
i18n.addResourceBundle(
  'de_DE',
  'console',
  deepmerge(convertRaTranslationsToI18next(deRaMessages), deRaOverrides),
  true,
  false
);
i18n.addResourceBundle(
  'es_ES',
  'console',
  deepmerge(convertRaTranslationsToI18next(esRaMessages), esRaOverrides),
  true,
  false
);

export { i18n };
