import { FC, Fragment, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { authenticationStore } from '@Stores';

const AdminProtected: FC<{ children: ReactNode }> = ({ children }) => {
  const { me, adminRole } = authenticationStore;

  if (me?.role !== adminRole) return null;
  return <Fragment>{children}</Fragment>;
};

export default observer(AdminProtected);
