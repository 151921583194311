import { action, makeObservable, observable } from 'mobx';
import { BaseStore } from '@infra/state-stores';
import {
  GetOrganizationResponseDTO,
  OrganizationsApi,
} from '@infra/daslab-sdk';
import { AxiosResponse } from 'axios';
import { authenticationStore } from '@Stores';
import { AxiosSDKError, sortByProperty } from '@infra/helpers';

type organization = Pick<GetOrganizationResponseDTO, 'id'> & { name: string };
type organizationsStore = BaseStore<OrganizationsApi> & {
  organizations: organization[];
  needsUpdate: boolean;
  setNeedsUpdate: (needsUpdate: organizationsStore['needsUpdate']) => void;
  updateOrganizations: () => void;
};

class OrganizationsStore
  extends BaseStore<OrganizationsApi>
  implements organizationsStore
{
  public organizations: organizationsStore['organizations'] = [];
  public needsUpdate: organizationsStore['needsUpdate'] = false;

  constructor() {
    super(OrganizationsApi);
    makeObservable(this, {
      organizations: observable,
      needsUpdate: observable,
      setOrganizations: action,
      setNeedsUpdate: action,
    });

    if (authenticationStore.me) this.updateOrganizations();
  }

  setOrganizations = (organizations: organizationsStore['organizations']) => {
    this.organizations = organizations;
  };

  setNeedsUpdate = (needsUpdate: organizationsStore['needsUpdate']) => {
    this.needsUpdate = needsUpdate;
  };

  updateOrganizations = async () => {
    if (this.executing) return;
    this.setExecuting(true);
    try {
      const { data } =
        (await this.api.listOrganizations()) as unknown as AxiosResponse<
          GetOrganizationResponseDTO[]
        >;
      const organizations = data.map(({ id, settings }) => ({
        id,
        name: settings?.name?.display || 'Unknown',
      }));
      this.setOrganizations(sortByProperty(organizations, 'name'));
      this.setInitiated(true);
      this.setNeedsUpdate(false);
    } catch (e) {
      const err = e as AxiosSDKError;
      this.setErrors(err);
      throw err;
    } finally {
      this.setExecuting(false);
    }
  };
}

export const organizationsStore: organizationsStore = new OrganizationsStore();
