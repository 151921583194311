import { DataProvider } from 'react-admin';
import { organizations } from './organizations';
import { apiCredentials } from './apiCredentials';
import { products } from './products';
import { orders } from './orders';

type resource = string;
const providers: Map<string, DataProvider<resource>> = new Map();

providers.set('organizations', organizations);
providers.set('apiCredentials', apiCredentials);
providers.set('products', products);
providers.set('orders', orders);

const getProvider = (resource: resource) => {
  const provider = providers.get(resource);
  if (!provider) throw new Error('No data provider found for this resource');
  return provider;
};

export const dataProvider: DataProvider<resource> = {
  getList: async (resource, params) =>
    getProvider(resource).getList(resource, params),
  getOne: async (resource, params) =>
    getProvider(resource).getOne(resource, params),
  getMany: async (resource, params) =>
    getProvider(resource).getMany(resource, params),
  getManyReference: async (resource, params) =>
    getProvider(resource).getManyReference(resource, params),
  create: async (resource, params) =>
    getProvider(resource).create(resource, params),
  update: async (resource, params) =>
    getProvider(resource).update(resource, params),
  updateMany: async (resource, params) =>
    getProvider(resource).updateMany(resource, params),
  delete: async (resource, params) =>
    getProvider(resource).delete(resource, params),
  deleteMany: async (resource, params) =>
    getProvider(resource).deleteMany(resource, params),
  toJSON: async () => {},
};
