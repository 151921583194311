import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Layout as RALayout, LayoutProps, useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { authenticationStore } from '@Stores';
import { ROUTES } from '@/Routes';
import AdminMenu from '@/components/AdminMenu';

export const AdminLayout = observer((props: LayoutProps) => {
  const { me, executing } = authenticationStore;
  const redirect = useRedirect();
  const location = useLocation();

  useEffect(() => {
    if (
      me &&
      !me.organizationId &&
      location.pathname !== ROUTES.AuthCreateOrganization
    )
      redirect(ROUTES.AuthCreateOrganization);
  }, [me]);

  if (!executing && !me?.accountId)
    return (
      <RALayout
        menu={() => {
          redirect(ROUTES.Login);
          return null;
        }}
        appBar={() => null}
      />
    );

  return <RALayout {...props} menu={AdminMenu} />;
});
