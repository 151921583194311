import { i18n } from '@/i18n';
import { authenticationStore } from '@Stores';
import {
  auth,
  signInWithEmailAndPassword,
  AuthError,
  signOut,
} from '@infra/firebase';
import { publicAccountsService } from '@infra/observable-core';
import { AuthProvider } from 'react-admin';

export type SignInWithEmailAndPasswordRequestDTO = {
  email: string;
  password: string;
};

export const authProvider: AuthProvider = {
  login: async ({ email, password }: SignInWithEmailAndPasswordRequestDTO) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      return await authenticationStore.syncUserState(true);
    } catch (error) {
      throw (error as AuthError).code;
    }
  },
  register: async ({
    email,
    password,
  }: SignInWithEmailAndPasswordRequestDTO) => {
    return publicAccountsService.createAccount({
      email,
      password,
      tenantId: import.meta.env.VITE_FIREBASE_TENANT_ID,
      locale: publicAccountsService.getLocaleFromI18n(i18n.language),
    });
  },
  logout: async () => {
    await signOut(auth);
    authenticationStore.clear();
  },
  checkAuth: async () => {
    if (!auth?.currentUser) return Promise.reject();
    await authenticationStore.syncUserState();
    return Promise.resolve();
  },
  checkError: (error: any) => {
    if (!error?.code) return Promise.resolve();
    const logoutErrorCodes: string[] = [
      'auth/invalid-user-token',
      'auth/user-token-expired',
    ];
    return logoutErrorCodes.includes(error.code)
      ? Promise.reject()
      : Promise.resolve();
  },
  getIdentity: async () => {
    if (!auth.currentUser) throw new Error('No user logged in');
    await authenticationStore.syncUserState();
    return { id: auth.currentUser.uid, ...authenticationStore.me };
  },

  getPermissions: async () => {
    if (!auth.currentUser) return null;
    await authenticationStore.syncUserState();
    return authenticationStore.me?.role;
  },
};
