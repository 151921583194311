import { i18n } from '@/i18n';

const { t, changeLanguage, language, languages } = i18n;

export const i18nProvider = {
  translate: t,
  changeLocale: async (language: string) => {
    await changeLanguage(language);
  },
  getLocale: () => language,
  getLocales: () =>
    languages.map((language) => ({ locale: language, name: language })),
};
