import { FC } from 'react';
import { Menu } from 'react-admin';
import { observer } from 'mobx-react';
import { authenticationStore } from '@Stores';
import SettingsIcon from '@mui/icons-material/Settings';
import LayersIcon from '@mui/icons-material/Layers';
import AdminProtected from '@/components/common/AdminProtected';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';

const AdminMenu: FC = observer(() => {
  const { me } = authenticationStore;

  return (
    <Menu>
      <Menu.Item
        to={`/organizations/${me?.organizationId}`}
        primaryText="organizations.edit.title"
        leftIcon={<SettingsIcon />}
      />
      <Menu.Item
        to={`/orders`}
        primaryText={'resources.orders.name'}
        leftIcon={<MonitorHeartIcon />}
      />
      <AdminProtected>
        <Menu.Item
          to={`/products`}
          primaryText={'resources.products.name'}
          leftIcon={<LayersIcon />}
        />
      </AdminProtected>
    </Menu>
  );
});

export default AdminMenu;
