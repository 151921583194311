import {TrackingTool} from "../TrackingTool";
import {Attributes} from "@opentelemetry/api";
import HyperDX from "@hyperdx/browser";

export class HyperDXTool extends TrackingTool
{
  isAvailable()
  {
    return Boolean(HyperDX);
  }

  trackEvent(eventName: string, data: Attributes)
  {
    HyperDX.addAction(eventName, data);
  }
}
