import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router';
import { psConsoleV2 } from '@infra/ui-design-system';
import { COMPONENTS, ROUTES } from '@/Routes';
import { Shell } from '@/components';
import { authProvider, i18nProvider, dataProvider } from '@Providers';
import { AdminLayout } from '@/components/AdminLayout';

export const App = () => {
  return (
    <Shell>
      <Admin
        theme={psConsoleV2}
        i18nProvider={i18nProvider}
        authProvider={authProvider}
        dataProvider={dataProvider}
        layout={AdminLayout}
        loginPage={COMPONENTS.Login}
      >
        <Resource
          name="orders"
          list={COMPONENTS.ListOrders}
          show={COMPONENTS.ShowOrder}
        />
        <Resource
          name="products"
          list={COMPONENTS.ListProducts}
          edit={COMPONENTS.EditProduct}
          create={COMPONENTS.CreateProduct}
        />
        <Resource name="organizations" edit={COMPONENTS.EditOrganization} />

        <CustomRoutes noLayout>
          <Route path={ROUTES.Register} element={<COMPONENTS.Register />} />
          <Route
            path={ROUTES.ResetPassword}
            element={<COMPONENTS.ResetPassword />}
          />
          <Route
            path={ROUTES.ResetCheckInBox}
            element={<COMPONENTS.ResetCheckInBox />}
          />
          <Route
            path={ROUTES.SetNewPassword}
            element={<COMPONENTS.SetNewPassword />}
          />
          <Route
            path={ROUTES.AuthCreateOrganization}
            element={<COMPONENTS.AuthCreateOrganization />}
          />
        </CustomRoutes>
      </Admin>
    </Shell>
  );
};
