import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { ordersService, accountsService } from '@infra/observable-core';
import { DeleteResult } from 'ra-core/dist/cjs/types';
import { eventTracker } from '@infra/helpers';
import { CancelOrderRequestDTOReasonEnum } from '../../../../../../libs/daslab-sdk/src';
import { OrderCancellationReasonType } from '../../entities/order.cancellation-reason';

export const orders: DataProvider<string> = {
  getOne: async (resource, params): Promise<GetOneResult> => {
    const { id } = params;
    if (!id || typeof id !== 'string') throw new Error('Missing parameter: id');
    const order = await ordersService.getById(id);
    return { data: order };
  },
  getList: async (resource, params: GetListParams): Promise<GetListResult> => {
    const { pagination, sort, filter } = params;
    const offset = (pagination.page - 1) * pagination.perPage;
    const { data, meta } = await ordersService.list(
      [`${sort.order === 'DESC' ? '-' : ''}${sort.field},id`],
      { offset, limit: pagination.perPage },
      filter
    );
    return { data, total: meta.total };
  },
  delete: async (resource, params): Promise<DeleteResult> => {
    const { id, previousData } = params;
    if (!id || typeof id !== 'string') throw new Error('Missing parameter: id');
    if (previousData?.isFinalized)
      throw new Error(`The order ${id} is finalized`);
    const order = await ordersService.cancelById(id, 'OTHER');
    eventTracker.trackGlobalEvent('order-deleted', {
      id: order.id,
      reference: order.reference,
      organizationId: order.organizationId,
    });
    return { data: order };
  },
  update: async (resource, params: UpdateParams): Promise<any> => {
    const { meta } = params;
    if (meta?.action === 'cancel') {
      const reason = meta.cancellationReason
        ? (meta.cancellationReason as OrderCancellationReasonType)
        : undefined;
      return customOrderMethods.cancel(params, reason);
    } else if (meta?.action === 'recreate') {
      const reason = meta.cancellationReason
        ? (meta.cancellationReason as OrderCancellationReasonType)
        : undefined;
      return customOrderMethods.recreate(params, reason);
    } else if (meta?.action === 'anonymise')
      return customOrderMethods.anonymiseAccount(params);
    eventTracker.trackGlobalEvent(`order-${meta?.action || 'update'}`, {
      id: params?.id,
    });
    throw new Error('this method is not implemented');
  },
  create: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  getMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  getManyReference: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  updateMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  deleteMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
};

type CustomOrderMethods = {
  cancel: (
    params: UpdateParams,
    cancellationReason?: CancelOrderRequestDTOReasonEnum
  ) => Promise<UpdateResult>;
  recreate: (
    params: UpdateParams,
    cancellationReason?: CancelOrderRequestDTOReasonEnum
  ) => Promise<UpdateResult>;
  anonymiseAccount: (params: UpdateParams) => Promise<UpdateResult>;
};

const customOrderMethods: CustomOrderMethods = {
  cancel: async (
    params: UpdateParams,
    cancellationReason?: CancelOrderRequestDTOReasonEnum
  ): Promise<UpdateResult> => {
    const { id, previousData } = params;
    if (!id || typeof id !== 'string') throw new Error('Missing parameter: id');
    if (previousData?.isFinalized)
      throw new Error(`The order ${id} is finalized`);
    const order = await ordersService.cancelById(id, cancellationReason);
    return { data: order };
  },
  recreate: async (
    params: UpdateParams,
    cancellationReason?: CancelOrderRequestDTOReasonEnum
  ): Promise<UpdateResult> => {
    const { id, previousData } = params;
    if (!id || typeof id !== 'string') throw new Error('Missing parameter: id');
    if (previousData?.isFinalized)
      throw new Error(`The order ${id} is finalized`);
    await ordersService.recreate(id, cancellationReason);
    return { data: previousData };
  },
  anonymiseAccount: async (params: UpdateParams): Promise<UpdateResult> => {
    const { id: email } = params;
    const data = await accountsService.anonymize({ email });
    return { data: { id: email, ...data } };
  },
};
