export function delay(ms): Promise<void>
{
  return new Promise(res => setTimeout(res, ms));
}

/**
 * Recursively compares two objects and returns an object containing only the modified fields.
 * This function does not currently handle Map, Set, Date, or RegExp objects.
 *
 * @param originalObject - The original object for comparison.
 * @param currentObject - The current object.
 * @returns An object containing only the fields that differ between the current and the original objects.
 */

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};
export const deepCompareObjects = <T extends Record<string, any>>(originalObject: T, currentObject: T): DeepPartial<T> => {
  return Object.keys(currentObject).reduce((changes, key) =>
  {
    const currentKey = key as keyof T;
    const isObject = (obj: any): obj is Record<string, any> => obj && typeof obj === 'object' && !Array.isArray(obj);

    if (isObject(currentObject[currentKey]) && isObject(originalObject[currentKey]))
    {
      const nestedChanges = deepCompareObjects(originalObject[currentKey], currentObject[currentKey]);
      if (Object.keys(nestedChanges).length > 0) changes[currentKey] = nestedChanges as any;
    }
    else if (Array.isArray(currentObject[currentKey]) && Array.isArray(originalObject[currentKey]))
    {
      const isArrayEqual = currentObject[currentKey].length === originalObject[currentKey].length &&
        currentObject[currentKey].every((item: any, index: number) =>
          deepCompareObjects(item, originalObject[currentKey][index]).length === 0);
      if (!isArrayEqual) changes[currentKey] = currentObject[currentKey]
    }
    else if (currentObject[currentKey] !== originalObject[currentKey])
    {
      changes[currentKey] = currentObject[currentKey];
    }

    return changes;
  }, {} as DeepPartial<T>);
};

/**
 * Removes keys with undefined values from a given object.
 * If all keys of the object have undefined values, returns undefined.
 *
 * @param {T} obj - The object to be cleaned. It can be of any type that extends a record with string keys.
 * @returns {T | undefined} - Returns the cleaned object if it has at least one key with a non-undefined value; otherwise, returns undefined.
 * @template T - A generic type extending Record<string, any> to represent the shape of the input object.
 */
export function cleanObject<T extends Record<string, any>>(obj: T): T | undefined {
  const cleanedObj = Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key as keyof T] = value;
    }
    return acc;
  }, {} as T);

  return Object.keys(cleanedObj).length === 0 ? undefined : cleanedObj;
}

/**
 * Sorts an array of objects by a specified property.
 *
 * @param {T[]} array - The array of objects to be sorted.
 * @param {keyof T} key - The key of the property to sort by.
 * @returns {T[]} A new array sorted based on the specified key.
 *
 * @example
 * const data = [{ name: "Charlie" }, { name: "Alice" }, { name: "Bob" }];
 * const sortedData = sortByProperty(data, 'name');
 * console.log(sortedData);
 */

export function sortByProperty<T extends Record<string, unknown>>(array: T[], key: keyof T): T[]
{
  return [...array].sort((a, b) =>
  {
    const valA = String(a[key]).trim().toLowerCase();
    const valB = String(b[key]).trim().toLowerCase();
    return valA.localeCompare(valB);
  });
}
