import { app, getAuth, useAuthState } from '@infra/firebase';
import { FC, Fragment, ReactNode } from 'react';
import { CircularProgress } from '@mui/material';

export const Shell: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, loading, error] = useAuthState(getAuth(app));
  if (loading)
    return (
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  return <Fragment>{children}</Fragment>;
};
