import {
  DataProvider,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { organizationsService } from '@infra/observable-core';
import { CreateParams, CreateResult } from 'ra-core/src/types';
import {
  CreateOrganizationRequestDTO,
  UpdateOrganizationRequestDTO,
} from '@infra/daslab-sdk';
import { GetListResult } from 'ra-core/dist/cjs/types';
import { organizationsStore } from '@/modules/stores/Organizations';
import { eventTracker } from '@infra/helpers';

export const organizations: DataProvider<string> = {
  getOne: async (
    resource,
    params: GetOneParams<RaRecord>
  ): Promise<GetOneResult> => {
    const { id } = params;
    if (!id || typeof id !== 'string') throw new Error('Missing parameter: id');
    const organisation = await organizationsService.getOrganization(id);
    return { data: organisation };
  },
  create: async (
    resource,
    params: CreateParams<CreateOrganizationRequestDTO>
  ): Promise<CreateResult> => {
    const { data } = params;
    const organization = await organizationsService.createOrganization(
      data as CreateOrganizationRequestDTO
    );
    organizationsStore.setNeedsUpdate(true);
    eventTracker.trackGlobalEvent('organization-created', {
      id: organization.id,
      type: organization.type,
    });
    return { data: organization };
  },
  update: async (
    resource,
    params: UpdateParams<UpdateOrganizationRequestDTO & RaRecord<string>>
  ): Promise<UpdateResult> => {
    const { data, id } = params;
    if (!id) throw new Error('Missing parameter: id');
    const {
      id: orgId,
      type,
      createdAt,
      updatedAt,
      email,
      password,
      audience,
      dialogWebhookUrl,
      ...updateData
    } = data;
    const organization = await organizationsService.updateOrganization(
      id,
      updateData
    );
    organizationsStore.setNeedsUpdate(true);
    eventTracker.trackGlobalEvent('organization-updated', {
      id: organization.id,
      type: organization.type,
    });
    return { data: organization };
  },
  getList: async (resource, params): Promise<GetListResult> => {
    const organizations = await organizationsService.listOrganizations();
    return { data: organizations, total: organizations.length };
  },
  getMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  getManyReference: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  updateMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  delete: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  deleteMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
};
