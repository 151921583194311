import {
  DataProvider,
  DeleteParams,
  DeleteResult,
  RaRecord,
} from 'react-admin';
import { apiCredentialsService } from '@infra/observable-core';
import { CreateParams, CreateResult } from 'ra-core/src/types';
import { CreateAccountRequestDTO } from '@infra/daslab-sdk';
import i18next from 'i18next';
import { GetListResult } from 'ra-core/dist/cjs/types';
import { eventTracker } from '@infra/helpers';

export const apiCredentials: DataProvider<string> = {
  create: async (
    resource,
    params: CreateParams<CreateAccountRequestDTO>
  ): Promise<CreateResult> => {
    const { data } = params;
    const { email, password, organizationId, roleId } = data;

    if (!email) throw new Error('Bad request: missing email');
    if (!password) throw new Error('Bad request: missing password');
    if (!organizationId) throw new Error('Bad request: missing organizationId');

    const createdAccount = await apiCredentialsService.createApiCredential({
      email,
      password,
      roleId,
      organizationId,
      tenantId: import.meta.env.VITE_FIREBASE_APICLIENTS_TENANT_ID,
      locale: apiCredentialsService.getLocaleFromI18n(i18next.language),
    });

    eventTracker.trackGlobalEvent('apiCredential-created', {
      organizationId,
      email,
      roleId,
    });
    return { data: createdAccount };
  },
  getList: async (resource, params): Promise<GetListResult> => {
    const { meta } = params;
    if (!meta) throw new Error('Missing meta parameter');
    const { organizationId, roleId } = meta;
    const data = await apiCredentialsService.getApiCredentialsList({
      organizationId,
      roleId,
    });
    return { data, total: data.length };
  },
  delete: async (
    resource,
    params: DeleteParams<RaRecord>
  ): Promise<DeleteResult> => {
    const { id } = params;
    const data = await apiCredentialsService.deleteApiCredentialFromId(
      id.toString()
    );
    eventTracker.trackGlobalEvent('apiCredential-deleted', { id });
    return { data };
  },
  getOne: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  update: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  getMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  getManyReference: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  updateMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  deleteMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
};
