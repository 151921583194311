import { me } from '@Stores';
import { AccountMeResponseDTORoleEnum } from '@infra/daslab-sdk';

const roles: Map<me['role'], me['roleId']> = new Map();

roles.set(
  AccountMeResponseDTORoleEnum.ApiClient,
  '9e04368a-2c31-4c19-866a-b4ae8842d859'
);
roles.set(
  AccountMeResponseDTORoleEnum.DaslabAdministrator,
  '4e0cfb07-a86b-4dab-a9ae-d6ac893e2c40'
);
roles.set(
  AccountMeResponseDTORoleEnum.DaslabSupportUser,
  'a4f227ec-cee8-4f10-92fd-44f72db2190c'
);
roles.set(
  AccountMeResponseDTORoleEnum.OrganizationOwner,
  'fc129b41-b1ff-4883-95ba-dcb89de723c2'
);
roles.set(
  AccountMeResponseDTORoleEnum.Patient,
  '07be9b99-c82b-4a43-a833-a57c425a800c'
);
roles.set(
  AccountMeResponseDTORoleEnum.Practitioner,
  '2996892b-1df3-4085-b892-01eac9002d0b'
);

export default roles;
