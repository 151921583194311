import { Attributes } from '@opentelemetry/api';
import {TrackingTool} from "./TrackingTool";
import {SmartlookTool} from "./tools/Smartlook";
import {HyperDXTool} from "./tools/HyperDX";
import {UmamiTool} from "./tools/Umami";
import {AttributeValue} from "@opentelemetry/api/build/src/common/Attributes";

type StringNullableRecord = Record<string, AttributeValue | Date | undefined>;

class EventTracker
{
  private tools: TrackingTool[];

  constructor()
  {
    this.tools = [
      new SmartlookTool(),
      new HyperDXTool(),
      new UmamiTool()
    ];
  }

  private normalizeData = <T = Attributes>(data: StringNullableRecord): T =>
  {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (value instanceof Date) acc[key] = value.toISOString();
      else if (Array.isArray(value)) acc[key] = value.join();
      else acc[key] = value ?? 'unknown';
      return acc;
    }, {} as Attributes) as T;
  }

  trackGlobalEvent(eventName: string, data: StringNullableRecord = {})
  {
    const normalizedData = this.normalizeData<Attributes>(data)
    this.tools.forEach(tool => {
      if (tool.isAvailable()) tool.trackEvent(eventName, normalizedData);
    });
  }
}

export const eventTracker = new EventTracker();
