import {TrackingTool} from "../TrackingTool";
import {Attributes} from "@opentelemetry/api";

export class UmamiTool extends TrackingTool
{
  isAvailable()
  {
    return typeof window?.umami?.track !== 'undefined';
  }

  trackEvent(eventName: string, data: Attributes)
  {
    window?.umami?.track(eventName, data)
  }
}
