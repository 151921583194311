import { action, makeObservable, observable } from 'mobx';
import { AccountMeResponseDTO } from '@infra/daslab-sdk';
import { accountsService } from '@infra/observable-core';
import { BaseStore } from '@infra/state-stores';
import roles from '@/modules/entities/roles';
import { AxiosSDKError } from '@infra/helpers';

export type me = AccountMeResponseDTO & { roleId: string | null };
type propertyMapper = Record<string, string | me | null>;
type authenticationStore = BaseStore<null> & {
  firstName: string;
  lastName: string;
  email: string;
  me: me | null;
  readonly adminRole: string;
  setFirstName: (firstName: authenticationStore['firstName']) => void;
  setLastName: (firstName: authenticationStore['lastName']) => void;
  setEmail: (firstName: authenticationStore['email']) => void;
  setMe: (me: AccountMeResponseDTO) => void;
  setProperties: (propertyMapper: propertyMapper) => void;
  syncUserState: (overwrite?: boolean) => Promise<authenticationStore['me']>;
  clear: () => void;
};

class AuthenticationStore
  extends BaseStore<null>
  implements authenticationStore
{
  public firstName: authenticationStore['firstName'] = '';
  public lastName: authenticationStore['lastName'] = '';
  public email: authenticationStore['email'] = '';
  public me: authenticationStore['me'] = null;
  public readonly adminRole: authenticationStore['adminRole'] =
    'daslab-administrator';
  private readonly roles: Map<me['role'], me['roleId']> = roles;

  constructor() {
    super(null);
    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      email: observable,
      me: observable,
      setFirstName: action,
      setLastName: action,
      setEmail: action,
      setMe: action,
      setProperties: action,
    });
  }

  setFirstName = (firstName: authenticationStore['firstName']) => {
    this.firstName = firstName;
  };
  setLastName = (lastName: authenticationStore['lastName']) => {
    this.lastName = lastName;
  };
  setEmail = (email: authenticationStore['email']) => {
    this.email = email;
  };
  setMe = (me: AccountMeResponseDTO) => {
    const roleId = this.roles.get(me.role) || null;
    this.me = { ...me, roleId };
  };

  clear = () => {
    this.setProperties({
      firstName: '',
      lastName: '',
      email: '',
      me: null,
    });
  };

  setProperties = (propertyMapper: propertyMapper) => {
    Object.keys(propertyMapper).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(this, key)) return;
      (this as any)[key] = propertyMapper[key];
    });
  };

  syncUserState = async (overwrite = false) => {
    if (!overwrite && this.me !== null) return this.me;
    if (this.executing) return await this.getSynchronizingUser();
    this.setExecuting(true);

    try {
      const user = await accountsService.getCurrent();
      this.setMe(user);
      return this.me;
    } catch (e) {
      const err = e as AxiosSDKError;
      this.setErrors(err);
      throw err;
    } finally {
      this.setExecuting(false);
    }
  };

  getSynchronizingUser = async (): Promise<authenticationStore['me']> => {
    if (this.me) return this.me;

    await new Promise((resolve) => {
      const checkMe = setInterval(() => {
        if (this.me) {
          clearInterval(checkMe);
          resolve(true);
        }
      }, 500);
    });

    return this.me;
  };
}

export const authenticationStore: authenticationStore =
  new AuthenticationStore();
