import {TrackingTool} from "../TrackingTool";
import Smartlook from "smartlook-client";

type SmartlookEventData = {[key: string]: string | number | boolean;}

export class SmartlookTool extends TrackingTool
{
  isAvailable()
  {
    return Smartlook?.initialized();
  }

  trackEvent(eventName: string, data: SmartlookEventData)
  {
    Smartlook.track(eventName, data);
  }
}
