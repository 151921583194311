import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import {
  CreateProductOrganizationRoleRequestDTO,
  CreateProductOrganizationRoleRequestDTONameEnum,
  CreateProductRequestDTO,
  GetProductResponseDTO,
  UpdateProductRequestDTO,
} from '@infra/daslab-sdk';
import { productsService } from '@infra/observable-core';
import { cleanObject } from '@infra/helpers';
import {
  providers,
  consultationProviderSettings,
  uiProduct,
} from '@/modules/types/products.types';
import { eventTracker } from '@infra/helpers';

function getProductFromUIProduct<T>(uiProduct: uiProduct): T {
  const {
    providers,
    consultationProviderSettings,
    id,
    createdAt,
    updatedAt,
    ...product
  } = uiProduct;
  const settings =
    consultationProviderSettings &&
    cleanObject<consultationProviderSettings>({
      ...consultationProviderSettings,
    });
  const organizationRoles = Object.keys(providers)
    .map((name) => {
      const organizationId = providers[name as keyof providers];
      if (!organizationId) return undefined;

      const organizationRole: CreateProductOrganizationRoleRequestDTO = {
        organizationId,
        name: name as CreateProductOrganizationRoleRequestDTONameEnum,
        ...(name ===
          CreateProductOrganizationRoleRequestDTONameEnum.ConsultationProvider &&
        settings
          ? { settings }
          : {}),
      };

      return organizationRole;
    })
    .filter(Boolean);
  return { ...product, organizationRoles } as T;
}

function getUIProductFromProduct(product: GetProductResponseDTO): uiProduct {
  const { organizationRoles, ...productData } = product;
  return organizationRoles.reduce(
    (acc: uiProduct, { name, organizationId, settings }) => {
      acc.providers[name as keyof providers] = organizationId;

      if (
        name ===
          CreateProductOrganizationRoleRequestDTONameEnum.ConsultationProvider &&
        settings
      ) {
        acc.consultationProviderSettings = {
          consultationPhoneNumber: settings?.phoneNumber || undefined,
          consultationUrl: settings?.consultationUrl || undefined,
          examinationUrl: settings?.examinationUrl || undefined,
        };
      }

      return acc;
    },
    { ...productData, providers: {}, consultationProviderSettings: {} }
  );
}

export const products: DataProvider<string> = {
  getOne: async (resource, params: GetOneParams): Promise<GetOneResult> => {
    const { id } = params;
    const product = await productsService.getOne(id);
    const data = getUIProductFromProduct(product);
    return { data };
  },
  create: async (resource, params: CreateParams): Promise<CreateResult> => {
    const { data } = params;
    const newProduct = getProductFromUIProduct<CreateProductRequestDTO>(
      data as uiProduct
    );
    const createdProduct = await productsService.create(
      newProduct as CreateProductRequestDTO
    );
    eventTracker.trackGlobalEvent('product-created', {
      id: createdProduct.id,
      sku: createdProduct.sku,
    });
    return { data: createdProduct };
  },
  update: async (resource, params: UpdateParams): Promise<UpdateResult> => {
    const { id: productId, data: requestData } = params;
    const updateData = getProductFromUIProduct<UpdateProductRequestDTO>(
      requestData as uiProduct
    );
    const data = await productsService.update(productId, updateData);
    eventTracker.trackGlobalEvent('product-updated', {
      id: data.id,
      sku: data.sku,
    });
    return { data };
  },
  getList: async (resource, params): Promise<GetListResult> => {
    const data = await productsService.getList();
    return { data, total: data.length };
  },
  delete: async (resource, params: DeleteParams): Promise<DeleteResult> => {
    const { id } = params;
    await productsService.delete(id);
    eventTracker.trackGlobalEvent('product-deleted', { id });
    return { data: { id } };
  },
  getMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  getManyReference: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  updateMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
  deleteMany: async (resource, params) => {
    throw new Error('this method is not implemented');
  },
};
